.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  color-scheme: #f76432;
}

.dropdown {
  position: absolute;
  top: 38px;
  left: -1px;
  border: 1px solid rgb(197, 197, 197);
  background: #fff;
  padding: 0;
  color: #0062cc;
  text-align: left;
  border-radius: 0.25rem;
}

.dropdown > p {
  margin: 0;
  padding: 0.375rem 0.88rem;
  border-bottom: 1px solid rgb(235, 235, 235);
  min-width: 140px;
}
.dropdown > p:hover {
  color: #fff;
  background-color: #0062cc;
}
.dropdown > p:last-child {
  border-bottom: 0 none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}